import React from 'react';

export const ModernInvoicePagePublicConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false
                },
                toolbar: {
                    display: false
                },
                footer: {
                    display: false
                },
                leftSidePanel: {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes: [
        {
            path: '/xml-view/:id?',
            component: React.lazy(() => import('./ModernInvoicePage'))
        }
    ]
};
